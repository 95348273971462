@import '../../../assets/scss/variables';

.PromptModal {
  z-index: 999999999;

  .modal-content {
    border: 0;
    padding: 15px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.44);
  }

  .modal-header {
    border: 0;
    .modal-title {
      font-size: 1.3rem;
      font-family: 'font-semibold';
    }
  }

  .modal-body {
    padding: 5px;
    padding-left: 1.1rem;
    padding-right: 1.1rem;
    font-size: 1.2rem;
  }

  .modal-footer {
    border: 0;
    padding: 10px;
    padding-top: 0;
    justify-content: start;
  }

  pre {
    color: $color-base;
    font-size: 1.2rem;
    font-family: 'font-regular';
    white-space: break-spaces !important;
  }

  .text-note {
    color: $danger;
    font-size: 0.9rem;
    font-family: 'font-regular';
  }
  .success-note {
    color: $success;
    font-family: 'font-regular';
  }
}

@import '../../assets/scss/variables';

$sidebar-bg-color: #ffffff !default;
$sidebar-color: #5b7583 !default;
$sidebar-width: 220px !default;
$sidebar-collapsed-width: 80px !default;
$highlight-bg-color: $success !default;
$highlight-color: $light !default;
$hover-color: $success !default;
$submenu-bg-color: #ffffff !default;
$submenu-bg-color-collapsed: #f4fffb !default;
$icon-bg-color: none !default;
$icon-size: 35px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;

.AppSidebar.dark {
  $sidebar-bg-color: #1d1d1d !default;
  $sidebar-color: #adadad !default;
  $sidebar-width: 220px !default;
  $sidebar-collapsed-width: 80px !default;
  $highlight-color: #d8d8d8 !default;
  $submenu-bg-color: #2b2b2b !default;
  $submenu-bg-color-collapsed: #2b2b2b !default;
}

@import '~react-pro-sidebar/dist/scss/styles.scss';
/*==========================================
-----------------APP HEADER--------------
========================================== */
.AppHeader {
  background-color: $sidebar-bg-color;
  height: $header-height;
  -webkit-box-shadow: 0px 4px 6px #ececec80;
  -moz-box-shadow: 0px 10px 5px #27272780;
  @media only screen and (max-width: 1224px) {
    .app-name {
      display: none;
    }
  }
  .app-logo {
    width: 130px !important;
  }
  .vertical-center {
    margin: auto;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  @media (max-width: 767px) {
    .app-logo {
      width: 90px !important;
      margin-top: 4px;
    }
  }
}

/*==========================================
-----------------APP SIDEBAR--------------
========================================== */
.AppSidebar.pro-sidebar {
  // color: #8d97ad !important;
  // min-width: 230px !important;
  // width: 230px !important;

  margin-top: $header-height;
  height: calc(100% - #{$header-height});
  .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    width: auto;
  }
  .pro-menu-item:not(.child-menu-item) {
    &:hover {
      background: #328e691c;
    }
  }
  .pro-sidebar-inner {
    .pro-sidebar-layout::-webkit-scrollbar {
      display: none;
    }
    // .pro-sidebar-layout {
    //   overflow: -moz-scrollbars-none;
    // }
  }
  .pro-sidebar-footer {
    border-top: 1px solid rgb(246, 246, 246) !important;
  }
  .pro-menu.shaped
    .pro-menu-item:not(.active):not(.submenu-active)
    > .pro-inner-item
    > .pro-icon-wrapper {
    background: none;
  }
  .pro-menu {
    .pro-menu-item {
      margin: 5px;
      border-radius: 4px;
      font-size: 1rem;
    }
    .pro-menu-item:not(.active) {
      .pro-inner-item {
        &:hover {
          color: inherit;
        }
        &:focus {
          color: inherit;
        }
      }
    }

    .pro-menu-item > .pro-inner-item {
      &:hover {
        color: inherit !important;
        svg {
          color: inherit !important;
        }
      }
      &:focus {
        color: inherit !important;
        svg {
          color: inherit !important;
        }
      }
    }

    .pro-menu-item:not(.pro-sub-menu) {
      > .pro-inner-item {
        padding: 10px 15px 10px 15px;
        margin-top: 5px;
        margin-bottom: 5px;
        .pro-icon {
          font-size: 2rem;
        }
        svg {
          fill: $sidebar-color !important;
          width: 2rem;
        }
      }
      &.active {
        background: $highlight-bg-color;
        font-family: 'font-semibold';
        color: #ffffff;
        svg {
          // stroke: red !important;
          fill: #fff !important;
        }
      }
    }
    .pro-sub-menu {
      .pro-inner-list-item {
        background: $submenu-bg-color-collapsed;
        &.popper-element {
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
          border-radius: 10px;
          padding-left: 0;
        }
      }
      &.submenu-active {
        > .pro-inner-item {
          background: $highlight-bg-color;
          color: $highlight-color !important;
          font-family: font-semibold;
          .pro-icon-wrapper {
            background: $icon-bg-color;
          }
          .pro-arrow {
            border-color: $highlight-color !important;
          }
        }
        .pro-inner-item {
          &:hover {
            color: $highlight-bg-color;
          }
        }
        .active {
          background: transparent;
          color: $success;
          border-left: none;
        }
      }
    }
  }
}

.AppSidebar.collapsed {
  .pro-inner-item {
    padding-left: 12px !important;
  }
}

.pro-sidebar.collapsed {
  min-width: 75px !important;
  width: 75px !important;
}

.popover-body,
.list-group-item {
  // background-color: $sidebar-bg-color !important;
  color: $sidebar-color !important;
  font: 'semi-bold' !important;
}

/*==========================================
-----------------APP CONTENT--------------
========================================== */
main.AppContent {
  padding-left: 0.5rem;
  margin-top: $header-height;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .PageContainer.card {
    height: 100%;
    width: 100%;
    border: 0;
    padding: 1rem 1rem;
  }
  @media (max-width: 767px) {
    .PageContainer.card {
      background: transparent;
      padding: 5px;
    }
  }
  @media (min-width: 768px) {
    .PageContainer.card {
      overflow-y: auto;
    }
  }
}
